import React from "react"
import getValue from "get-value"
import { GatsbyImage } from "gatsby-plugin-image"
import { getImageData } from "@utils/image"
import RichText from "@components/rich-text"

const AuthorBlock = ({ author }) => {
  const { bio, firstName, lastName, profileImage, positionTitle } = author
  const mainImageAssetId = getValue(profileImage, "asset._id")
  const imageData = getImageData(mainImageAssetId, {
    width: 500,
    height: 500,
    fit: "crop",
  })
  const fullName = `${firstName} ${lastName}`

  return (
    <section className="mb-24 bg-sky-blue">
      <div className="container flex flex-col items-center justify-center px-8 py-12 mx-auto sm:px-8 sm:py-24 sm:flex-row">
        <div className="relative w-32 h-32 mb-4 overflow-hidden rounded-full sm:w-48 sm:h-48 sm:mb-0">
          {imageData && (
            <GatsbyImage image={imageData} className="w-full h-full" alt="" />
          )}
        </div>
        <div className="max-w-md sm:ml-16">
          <div className="">
            <p className="mb-2 text-4xl text-black">{fullName}</p>
            <div className="uppercase">{positionTitle}</div>
            <br />
            <RichText text={bio} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AuthorBlock
